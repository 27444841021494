
a {	
  cursor: pointer;
  color:'#9297B0';
  font-size:'1vw'
  }
a:hover {
  cursor: pointer;
  color: #009550; }
  
