.MuiFormHelperText-root.Mui-error {
    color: #D11149 !important;
    margin-left: 20px !important;
}
.MuiInputBase-input:invalid {
    box-shadow: none;
    margin-top: 10px;
}
.Mui-focused {
    margin-top: 0px !important;
    margin-bottom: 0px !important;

}
.Mui-required {
    margin-top: 20px !important;

}
.Mui-error {
	 margin-top: -1px !important;
}
.MuiInputBase-input:-webkit-autofill {
    animation-name: mui-auto-fill;
    animation-duration: 5000s;
    margin-top: 10px;
}
.MuiInputLabel-animated {
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-bottom: 20px;}
.MuiInputBase-root{
	 margin-top: 20px !important;
}
.MuiFormLabel-root{
	margin-bottom: 20px !important;
}
.GridItem-grid-155 {
	width:1000px;
	padding:0 !important;
}