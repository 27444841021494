
a {
    cursor: pointer;
    color: #9297B0;
}

a:hover {
    cursor: pointer;
    color: #009550;
}

.item {
    cursor: pointer;
    color: #253A9F;
    font-family: Hind Vadodara, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 143%;
    /* identical to box height, or 23px */

    display: flex;
    align-items: center;
    margin-left: 10px;
    /* margin-bottom : 10px; */
}

.nav-gie {
    list-style: none;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 18px;
}

.nav-gie > li > a {
    font-family: Hind Vadodara, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #626886;

    padding: 0 18px;
    border-left: 1px solid #009550;
}

.btn-gie {
    font-size: 16px;
    line-height: 1.2 !important;
}

@media (max-width: 1350px) {
    .nav-gie > li > a {
        font-size: 12px !important;
    }

    .btn-gie {
        font-size: 12px !important;
    }
}

@media (max-width: 1220px) {
    .nav-gie {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 20px;
    }

    .nav-gie > li {
        margin: 0 0 27px;
        display: block;
    }

    .nav-gie > li > a {
        color: #253A9F;
        border: none;

        padding: 0;
    }
}
