
@media (min-width : 1280px){
    .footer_wrap {
        padding : 80px 120px 60px 120px
    }
}

@media (min-width : 768px) and (max-width : 1279px) {
    .footer_wrap {
        padding : 60px 35px
    }
}

@media (max-width : 767px) {
    .footer_wrap {
        padding : 60px 25px 80px 25px;
        height:1500px;
    }
}