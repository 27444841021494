.linear-icon-outline {
	width: 80px;
	height: 80px;
	background-image: radial-gradient(#fff 68%, transparent 68% 70%, #fff 70%),
		repeating-conic-gradient(#009550 0% 0.5%, transparent 1% 2%);
	display: flex;
	align-content: center;
}

.linear-icon-outline2 {
	width: 5.5vw;
	height: 5.5vw;
	background-image: radial-gradient(#fff 68%, transparent 68% 70%, #fff 70%),
		repeating-conic-gradient(#009550 0% 0.5%, transparent 1% 2%);
	display: flex;
	align-content: center;
}

.linear-icon-outline3 {
	width: 5vw;
	height: 5vw;
	background-image: radial-gradient(#fff 68%, transparent 68% 70%, #fff 70%),
		repeating-radial-gradient(#009550 0% 0.5%, white 0% 2%, transparent 1%
		2%);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	display: flex;
	align-content: center;
	opacity: 10;
}

.linear-icon-outline4 {
	width: 5vw;
	height: 5vw;
	border: 1px dotted #009550;
	border-radius: 50%;
	align-content: center;
	background-position: center center;
	display: flex;
}