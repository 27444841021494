.MuiFormLabel-root.Mui-focused {
    color: #253A9F !important;
    font-family: Hind Vadodara,sans-serif;
    font-style: 'normal';
    font-weight: 'bold !important';
    line-height: '135%';
    font-size:'30px !important';
}
.MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    margin-top: 24px;
}