
a {	
  color:'#626886';
  cursor: 'pointer'
  }
a:hover {
   cursor: pointer !important;
   color: #009550 !important;
   text-decoration: none; }
 i:hover {
   cursor: pointer;
   color: #009550;
   text-decoration: none; }
  
